import React, { useState } from "react"
import { Link } from "gatsby"
import { FiAlignJustify } from "react-icons/fi"
import logo from "../assets/images/logo2.png"
import engXS from "../assets/images/engXS.png"
import LanguageSwitcher from './LanguageSwitcher'

const Navbar = () => {

  const [show, setShow] = useState(false)
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img className="logoNav" src={logo} alt="Apsiha logo"/>
          </Link>
          <button className="nav-btn" onClick={() => setShow(!show)}>
           <FiAlignJustify />
          </button>
        </div>
        <div className={show ? "nav-links show-links" : "nav-links"}>
          <Link
            to="/"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Naslovna
          </Link>
          <div className="dropdown-menu drop-link hidden">
            <input type="checkbox" id="menu" />
            <label htmlFor="menu">Usluge</label>
            <div className="menu-content">
                <ul>
                  <li>
                    <Link
                      to="/Usluge/psihoterapija"
                      className="nav-link"
                      activeClassName="active-link"
                      onClick={() => setShow(false)}
                    >
                      Psihoterapija
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/Usluge/savjetovanje-ekspata"
                        className="nav-link"
                        activeClassName="active-link"
                        onClick={() => setShow(false)}
                      >
                        Savjetovanje ekspata
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Usluge/dobrobit-zaposlenika"
                        className="nav-link"
                        activeClassName="active-link"
                        onClick={() => setShow(false)}
                      >
                        Dobrobit zaposlenika
                      </Link>
                    </li>
                </ul>
            </div>
          </div>
          <Link
            to="/Services"
            className="nav-link hidden-lg"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Usluge
          </Link>
          <Link
            to="/blog"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Blog
          </Link>
          {/*<Link
            to="/media"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Mediji
          </Link>*/}
          <Link
            to="/Gallery"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Galerija
          </Link>
          <Link
            to="/About"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            O nama
          </Link>
          {/*<div className="nav-link center" id="lang-en">
            <Link to="/en/index">
            <img src={engXS} alt="engleski"/>
            </Link>
          </div>*/}
          <LanguageSwitcher />
          <div className="contact-link">
            <Link to="/Contact" className="btn-contact" onClick={() => setShow(false)}>
              Kontakt
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
