import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Services = ({data}) => {
  const usluge = data.usluga.nodes

  return (
    <Layout>
      <SEO title="Services" />
      <main className="page media-page">
      <div className="center">
      <h1><strong className="pageNaslov">Apsiha </strong>usluge</h1>
      </div>
      <div className="whiteSection boxedWhite" id="usluge">
          <div className="whiteSection-container">
            <div className="whiteSection-text">
            <div className="tags-list" id="tags">
            {usluge.map(usluge =>( 
              <Link to={"../Usluge/" + usluge.frontmatter.slug} key={usluge.id}>
                <div className="tagThumb">
                <GatsbyImage
                  image={getImage(usluge.frontmatter.thumb.childImageSharp.gatsbyImageData)}
                  alt={usluge.frontmatter.title}
                  />
                  <div className="tagDescript">
                  <h3><strong>{usluge.frontmatter.title}</strong></h3>
                  <p>{usluge.frontmatter.description}</p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
          </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Services

export const query = graphql` 
query Usluge {
    usluga: allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {frontmatter: {type: {eq: "usluge_input"}}}
    ) {
      nodes {
        frontmatter {
          slug
          order
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: CONTAIN}
                width: 450
              )
            }
          }
        }
        id
      }
    }
  }
`
